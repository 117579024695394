import React from 'react'
import { NavLink, useLocation, matchPath } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

interface AccountNavigationLinkProps {
  route: string

  template: string

  imgSource: string

  selectedImgSource: string

  text: string
}

export const AccountNavigationLink: React.FC<AccountNavigationLinkProps> =
  ({
     route,
     template,
     imgSource,
     selectedImgSource,
     text
   }) => {
    const classes = useStyles()
    const location = useLocation()

    const isSelectedLink = matchPath(location.pathname, {path: template})
    const imgSrc = isSelectedLink ? selectedImgSource : imgSource

    return (
      <ListItem
        component={NavLink}
        to={route}
        className={clsx('nav_account_link', isSelectedLink && 'nav_account_link_selected', classes.listItem)}
        button
        disableRipple
      >
        <ListItemIcon
          className={clsx(classes.listItemIcon, 'nav_account_link', isSelectedLink && 'nav_account_link_selected')}>
          <img src={imgSrc} alt={text}/>
        </ListItemIcon>
        <ListItemText
          className={clsx(classes.listItemText, 'nav_account_link', isSelectedLink && classes.listItemTextSelected)}
          primary={text}
        />
      </ListItem>
    )
  }

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: 30
  },
  listItem: {
    padding: '5px 0 0 10px',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  listItemText: {
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '14.4px'
    }
  },
  listItemTextSelected: {
    '& span': {
      color: '#3987CF',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '14.4px'
    }
  }
}))