import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Switch, SwitchProps } from '@material-ui/core'

export type IOSSwitchProps =
  Omit<SwitchProps, 'classes' | 'disableRipple' | 'focusVisibleClassName'>

export const IOSSwitch =
  (props: IOSSwitchProps) => {
    const classes = useStyles()

    return (
      <Switch
        disableRipple
        focusVisibleClassName={classes.focusVisible}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    )
  }

const useStyles = makeStyles(
  theme => ({
    root: {
      width: 35,
      height: 18,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(17px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#3987CF',
          opacity: 1,
          border: 'none'
        }
      }
    },
    thumb: {
      width: 16,
      height: 16
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: '#D7E7F5',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
  }))