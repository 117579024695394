import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../hooks/storeHook'

import { Box, Grid } from '@material-ui/core'

import { MoySkladSyncInfo } from '../../MoySkladSyncInfo'
import { MoySkladAccountForm } from '../../MoySkladAccountForm'
import { MoySkladStatusMapPanel } from '../../MoySkladStatusMapPanel'
import { MoySkladWebhookPanel } from '../../MoySkladWebhookPanel'
import { appRoutes } from '../../../common/appRoutes'
import { useHistory } from 'react-router-dom'

export const MoySkladSettingsScreen =
  observer(
    () => {
      const history = useHistory()
      const {syncStore} = useStore()

      useEffect(() => {
        const updatedRoute = appRoutes.MoySklad.settings.configuration.route(
          {
            integration: 'MoySklad',
            accountId: syncStore.moySkladAccount?.id ?? 0
          })

        history.replace(updatedRoute)
      }, [syncStore.moySkladAccount, history])

      return (
        <Box style={{width: 'calc(100vw - 320px)'}}>
          <Grid container direction="column">
            <Grid item lg={7} xl={7}>
              <MoySkladSyncInfo/>
            </Grid>

            <Grid item lg={7} xl={7}>
              <MoySkladAccountForm/>
            </Grid>

            <Grid item lg={7} xl={7}>
              <MoySkladStatusMapPanel/>
            </Grid>

            <Grid item lg={7} xl={7}>
              <MoySkladWebhookPanel/>
            </Grid>

          </Grid>
        </Box>
      )
    })