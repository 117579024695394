import React from 'react'
import { observer } from 'mobx-react-lite'

import { CircularProgress, FormHelperText } from '@material-ui/core'

import { MyTable } from './MyTable'

import { ITableData } from '../types/tableTypes'
import { WbPrintOrderColumnId, wbPrintOrderColumns } from '../types/wbColumns'
import { WbPrintOrderModel } from '../server/mpsklad_core/Models/WbPrintOrderModel'

export type WbPreviewPrintTableProps = {
  orders: ITableData<WbPrintOrderModel, WbPrintOrderColumnId>
}

export const WbPreviewPrintTable =
  observer(
    ({orders}: WbPreviewPrintTableProps) => {
      if (orders == null) {
        return <CircularProgress size={20}/>
      }

      return (
        <>
          <FormHelperText>
            Выбрано заказов: {orders.selectedRowCount}
          </FormHelperText>

          <MyTable
            table={orders}
            columns={wbPrintOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </>)
    })