// NOTE: Ignore sending height to MS via postMessage - let the iframe have 100% height, and scroll only inside out app.
//  Resizing the iframe allows scroll for the whole page, but disallows scrolling from within the iframe - this is bad.

/**
 * Fixes scroll jumps within MS app iframe when certain buttons are pressed.
 * Use on Menu, Popover, Select (via MenuProps), TablePagination (via MenuProps inside SelectProps).
 * TODO: Refactor Selects into a custom component
 */
export const fixMsAppScrollProps = {
  autoFocus: false,
  disableAutoFocus: true
}