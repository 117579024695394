import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

export type AdditionalPropertiesSettingsActionButtonsProps = {
  isLoading: boolean,
  onSubmit: (e: React.FormEvent) => Promise<void>
}

export const AdditionalPropertiesSettingsActionButtons = observer(
  ({isLoading, onSubmit}: AdditionalPropertiesSettingsActionButtonsProps) => {

    const classes = useStyles()

    return (
      <Box display="flex">
        <button
          type="button"
          className={clsx(classes.cancelButton, 'default-button')}
          disabled={isLoading}
        >
          <p>Отменить</p>
        </button>

        <button
          className={clsx(classes.saveButton, 'default-button')}
          disabled={isLoading}
          onClick={onSubmit}
        >
          <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
        </button>

      </Box>
    )
  })
const useStyles = makeStyles(
  () => ({
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  }))