import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, TableCell, TableRow } from '@material-ui/core'

import { useMessages } from '../hooks/snackbarHooks'

import { formatDateTimeUnix } from '../common/dateTimeUtility'

import { integrationTypeNames } from '../types/integrationTypeUtils'
import { SyncLogModel } from '../server/mpsklad_core/Models/SyncLogModel'
import { SyncLogSeverity } from '../server/mpsklad_core/Entity/SyncLogSeverity'

export const SyncLogRow =
  observer(
    ({log}: {log: SyncLogModel}) => {
      const classes = useStyles()

      const {showInfo} = useMessages()

      const onMessageClick = async () => {
        await navigator.clipboard.writeText(log.message)
        showInfo('Сообщение скопировано')
      }

      const onIdClick = async () => {
        await navigator.clipboard.writeText(log.id)
        showInfo('Id скопирован')
      }

      return (
        <TableRow>
          <TableCell className={'table-border'}>
            <span className={classes.text}>{formatDateTimeUnix(log.createdAt)}</span>
          </TableCell>

          <TableCell className={clsx(
            'table-border',
            log.severity === SyncLogSeverity.Info && classes.info,
            log.severity === SyncLogSeverity.Warning && classes.warning,
            log.severity === SyncLogSeverity.Error && classes.error
          )}>
            <span
              className={classes.text}>{syncLogSeverityNames[log.severity]} | {integrationTypeNames[log.integrationType]} #{log.accountId}</span>
          </TableCell>

          <TableCell className={'table-border'}>
            <span className={classes.text}>{log.message}</span>

            <IconButton title="Копировать" size="small" className={classes.iconButton} onClick={onMessageClick}>
              <FileCopy fontSize="small"/>
            </IconButton>
          </TableCell>

          <TableCell className={'table-border'}>
            <span className={classes.text}>{log.id}</span>

            <IconButton title="Копировать" size="small" className={classes.iconButton} onClick={onIdClick}>
              <FileCopy fontSize="small"/>
            </IconButton>
          </TableCell>
        </TableRow>
      )
    }
  )

export const syncLogSeverityNames: {
  [K in SyncLogSeverity]: string
} = {
  [SyncLogSeverity.Info]: 'Инфо',
  [SyncLogSeverity.Warning]: 'Предупреждение',
  [SyncLogSeverity.Error]: 'Ошибка'
}

const useStyles = makeStyles(
  ({palette}) => ({
    text: {
      fontSize: 12
    },
    error: {
      background: palette.mpSkladSecondary.lightRed
    },
    warning: {
      background: palette.mpSkladSecondary.orange
    },
    info: {
      background: palette.mpSkladSecondary.green
    },
    iconButton: {
      marginLeft: 5,
      opacity: 0,
      'td:hover &': {
        opacity: 1
      }
    }
  })
)