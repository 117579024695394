import React from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { SideBarNavigation } from './SideBarNavigation'
import { Header } from './Header'
import { Footer } from './Footer'

import clsx from 'clsx'

import { SettingsRoute } from './Settings/SettingsRoute'
import { PrintRoute } from './Print/PrintRoute'
import { ImportRoute } from './Import/ImportRoute'
import { ProductsRoute } from './Products/ProductsRoute'
import { OrdersRoute } from './Orders/OrdersRoute'

import { AccountScreen } from './AccountScreen'
import { SyncLogsScreen } from './SyncLogsScreen'
import { AdminUsersScreen } from './AdminUsersScreen'
import { ProductDiffsScreen } from './ProductDiffsScreen'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

export const AppRouter =
  observer(() => {
    const {hasMoySkladApp} = useStore()

    return (
      <div className={clsx('App', {'ms-app': hasMoySkladApp})}>
        <BrowserRouter>
          <div className="app_container">
            <Header/>

            <div className="main_content">
              <SideBarNavigation/>

              <Switch>
                <Route path={appRoutes.CommonStore.print.template} component={PrintRoute}/>

                <Route path={appRoutes.CommonStore.settings.template} component={SettingsRoute}/>

                <Route path={appRoutes.CommonStore.products.template} component={ProductsRoute}/>

                <Route path={appRoutes.Diffs} component={ProductDiffsScreen}/>

                <Route path={appRoutes.CommonStore.orders.template} component={OrdersRoute}/>

                <Route path={appRoutes.CommonStore.import.template} component={ImportRoute}/>

                <Route path={appRoutes.Account} component={AccountScreen}/>

                <Route path={appRoutes.AdminUsers} component={AdminUsersScreen}/>

                <Route path={appRoutes.CommonStore.syncLogs.template} component={SyncLogsScreen}/>

                <Route path={appRoutes.MoySkladApp.template}>
                  <Redirect to={appRoutes.MoySklad.settings.initial}/>
                </Route>

                <Route path={appRoutes.MoySkladAppOld}>
                  <Redirect to={appRoutes.MoySklad.settings.initial}/>
                </Route>

                <Redirect from={'*'} to={appRoutes.MoySklad.settings.initial}/>
              </Switch>
            </div>

            <Footer/>
          </div>
        </BrowserRouter>
      </div>
    )
  })