import React, { FormEvent } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import {
  Box,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import { MoySkladStatusMapRow } from './MoySkladStatusMapRow'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { required } from '../common/objectUtils'
import { msAppUrls } from '../common/urlUtility'

import { MoySkladStatusMapsModel } from '../server/mpsklad_core/Models/MoySkladStatusMapsModel'
import { MoySkladStatusMapModel } from '../server/mpsklad_core/Models/MoySkladStatusMapModel'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

export type MoySkladStatusMapFormProps = {
  statusMaps: MoySkladStatusMapsModel

  resetStatusMaps: () => void
}

export const MoySkladStatusMapForm =
  observer(
    ({statusMaps, resetStatusMaps}: MoySkladStatusMapFormProps) => {
      const {showSuccess} = useMessages()

      const logic = useLogic()

      const classes = useStyles()

      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const onChange =
        (map: MoySkladStatusMapModel, statusId: string) => {
          const selectedStatus = statusMaps.statuses.find(_ => _.id === statusId)

          runInAction(() => {
            map.moySkladId = selectedStatus?.id
            map.moySkladName = selectedStatus?.name
          })
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          setIsLoading()

          try {
            await logic.setMoySkladStatusMaps(required(statusMaps).maps)
            showSuccess('Сохранено!')
            resetStatusMaps()
          } finally {
            setNotLoading()
          }
        }

      return (
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Table>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      MpSklad
                    </FormLabel>
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      МойСклад
                    </FormLabel>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  statusMaps.maps.map(
                    map =>
                      <MoySkladStatusMapRow
                        key={map.myOrderStatus}
                        map={map}
                        statuses={statusMaps.statuses}
                        onChange={onChange}
                      />
                  )
                }
              </TableBody>
            </TableContainer>
          </Table>

          {
            statusMaps.statuses.length === 0 &&
            <Typography variant="body1" color="error">
              Не найдены статусы для Заказа покупателя в МоёмСкладе.
              Создайте статусы на странице <a
              href={msAppUrls.orders}
              target="_blank"
              rel="noreferrer">Продажи - Заказы покупателей</a>: Статус - Настроить.
            </Typography>
          }

          <Box marginLeft={1}>
            <button
              type="button"
              className={clsx(classes.cancelButton, 'default-button')}
              onClick={resetStatusMaps}
              disabled={isLoading}
            >
              <p>Отменить</p>
            </button>

            <button
              type="submit"
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}>
              <p> {isLoading ? 'Сохраняем...' : 'Сохранить'} </p>
            </button>
          </Box>
        </form>
      )
    })

const useStyles = makeStyles(
  () => ({
    label: {
      fontSize: '12px',
      lineHeight: '14.4px',
      textAlign: 'left'
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    cell: {
      border: 'none'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10,
      display: 'inline',
      '& p': {
        marginTop: 10
      }
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center',
      display: 'inline',
      '& p': {
        marginTop: 10
      }
    }
  })
)