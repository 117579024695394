import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box, FormHelperText,
  FormLabel,
  Grid, Typography
} from '@material-ui/core'

import { IOSSwitch } from '../../IOSSwitch'
import clsx from 'clsx'
import { useBoolState, useToggleState } from '../../../hooks/commonHooks'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'
import { DefaultSelect } from '../../DefaultSelect'
import { MoySkladDataLoadingComponent } from '../../MoySkladDataLoading'

export type YandexMarketReturnsSettingsProps = {
  msStores: MoySkladApiEntityOption[] | undefined;
  loadMsStores: () => Promise<void>;
}

export const WildberriesReturnsSettings =
  observer(({msStores, loadMsStores}: YandexMarketReturnsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()
    const {
      userSync: {
        setWildberriesReturnsSettings
      }
    } = useApi()

    const account = store.getHomeWbAccount()

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()
    const [isMsStoresLoading, setMsStoresLoading, setMsStoresNotLoading] = useBoolState()

    const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

    const [autoReturnsEnabled, toggleAutoReturnEnabled] = useToggleState(account?.isAutoReturnEnabled ?? false)

    const [autoRemoveCancelledMsOrderDocs, toggleAutoRemoveCancelledMsOrderDocs] = useToggleState(
      account?.autoRemoveCancelledMsOrderDocs ?? false)

    const classes = useSettingsPageStyles()

    useEffect(() => {
      if (!msStores) {
        setMsStoresLoading()
        loadMsStores().finally(setMsStoresNotLoading)
      }
    }, [msStores, loadMsStores, setMsStoresLoading, setMsStoresNotLoading])

    const onSubmit = async () => {
      try {
        setIsLoading()
        await setWildberriesReturnsSettings({
          accountId: account.id,
          isAutoReturnEnabled: autoReturnsEnabled,
          msSalesReturnId: msSalesReturnStoreId,
          autoRemoveCancelledMsOrderDocs: autoRemoveCancelledMsOrderDocs
        })

        setTimeout(logic.loadUserAccounts)

        showSuccess('Настройки возвратов обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке возвратов!')
      } finally {
        setNotLoading()
      }
    }

    const msStoreOptions = msStores?.map(store => ({
      value: store.id,
      label: store.name
    })) ?? []

    if (isMsStoresLoading) {
      return <MoySkladDataLoadingComponent/>
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ ВОЗВРАТОВ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Склад возвратов МоегоСклада
                  </FormLabel>
                </Box>

                <DefaultSelect
                  value={msSalesReturnStoreId}
                  onChange={setMsSalesReturn}
                  options={msStoreOptions}
                  fullWidth
                  placeholder="Выберите склад"
                  withUnselectedValue
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Создание документов о возврате
                  </FormLabel>
                  <IOSSwitch checked={autoReturnsEnabled} onChange={toggleAutoReturnEnabled}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Удалять документы при статусе Wildberries Отменён
                  </FormLabel>
                  <IOSSwitch checked={autoRemoveCancelledMsOrderDocs} onChange={toggleAutoRemoveCancelledMsOrderDocs}/>
                </Box>

                <FormHelperText className={classes.helperText}>
                  В заказе МоегоСклада будут удалены Отгрузка и Входящий платёж, если они были привязаны.
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Box display="flex">
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}
          >
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>
        </Box>
      </Box>
    )
  })