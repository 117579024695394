import { Link } from 'react-router-dom'
import { appRoutes } from '../common/appRoutes'
import { observer } from 'mobx-react-lite'
import { useStore } from '../hooks/storeHook'
import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import { IntegrationTypeKey } from '../types/integrationTypeUtils'

export type AddAccountButtonProps = {
  withHelpText?: boolean,
  integration: IntegrationTypeKey
}

export const AddAccountButton =
  observer(
    ({withHelpText, integration}: AddAccountButtonProps) => {
      const {canAddAccount} = useStore()
      const store = useStore()

      const classes = useStyles()

      const onClick = () => {
        store.unsetHomeNavAccount()
      }

      return (
        <Box className={classes.root}>
          {canAddAccount ? (<Link
            to={appRoutes.CommonStore.settings.route({accountId: 0, integration: integration})}
            style={{textDecoration: 'none'}}
            onClick={onClick}
          >
            <div className="nav_account_add">
              <p>Добавить</p>
              <p>+</p>
            </div>
          </Link>) : (
             <div className="nav_account_add">
               <p>Добавить</p>
               <p>+</p>
             </div>
           )}

          {withHelpText &&
           <Box className={clsx(classes.hint, 'default-border')}>
             <FormHelperText className={classes.helperText}>
               Для того, чтобы добавить аккаунт маркетплейса, нажмите на кнопку выше и перейдите к этапу ввода
               API-токена.
             </FormHelperText>
           </Box>
          }
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      width: 200
    },
    hint: {
      padding: 20,
      maxWidth: 160,
      position: 'absolute',
      backgroundColor: '#FBFBFD'
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    }
  })
)