import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogic, useStore } from '../hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { Box, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { AccountSyncSwitch } from './AccountSyncSwitch'

import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { accountNavigationLinks, appRoutes } from '../common/appRoutes'

import productsIcon from '../assets/products.svg'
import selectedProductsIcon from '../assets/selectedProducts.svg'
import ordersIcon from '../assets/order.svg'
import selectedOrderIcon from '../assets/selectedOrder.svg'
import settingsIcon from '../assets/setting.svg'
import selectedSettingsIcon from '../assets/selectedSetting.svg'
import printIcon from '../assets/print.svg'
import selectedPrintIcon from '../assets/selectedPrint.svg'
import importIcon from '../assets/import.svg'
import selectedImportIcon from '../assets/selectedImport.svg'
import logsIcon from '../assets/logs.svg'
import selectedLogsIcon from '../assets/logi.svg'
import removeAccountIcon from '../assets/cross.svg'
import instructions from '../assets/instructions.svg'

import { useMessages } from '../hooks/snackbarHooks'
import { getIntegrationTypeKey } from '../types/integrationTypeUtils'
import { AccountNavigationLink } from './AccountNavigationLink'

export type AccountNavigationProps = {
  isSelected: boolean
  title: string
  account: StoreAccountModelBase
  hasMoySkladApp: boolean
  onAccountChange: (accountIndex: number) => void
  accountIndex: number
};

const tabData = {
  [appRoutes.CommonStore.products.template]: {
    imgSource: productsIcon,
    selectedImgSource: selectedProductsIcon,
    text: 'Товары'
  },
  [appRoutes.CommonStore.orders.template]: {
    imgSource: ordersIcon,
    selectedImgSource: selectedOrderIcon,
    text: 'Заказы'
  },
  [appRoutes.CommonStore.settings.template]: {
    imgSource: settingsIcon,
    selectedImgSource: selectedSettingsIcon,
    text: 'Настройка'
  },
  [appRoutes.CommonStore.print.template]: {
    imgSource: printIcon,
    selectedImgSource: selectedPrintIcon,
    text: 'Печать'
  },
  [appRoutes.CommonStore.import.template]: {
    imgSource: importIcon,
    selectedImgSource: selectedImportIcon,
    text: 'Импорт'
  },
  [appRoutes.CommonStore.syncLogs.template]: {
    imgSource: logsIcon,
    selectedImgSource: selectedLogsIcon,
    text: 'Логи'
  }
}

export const AccountNavigation = observer(
  ({
     isSelected,
     title,
     account,
     onAccountChange,
     accountIndex
   }: AccountNavigationProps) => {

    const logic = useLogic()
    const {homeNavRequired, setHomeNav} = useStore()
    const history = useHistory()

    const {showSuccess, showError} = useMessages()
    const classes = useStyles()

    const currentLinks = useMemo(
      () => accountNavigationLinks(account.integrationType, account.id),
      [account.id, account.integrationType]
    )

    const onDeleteAccount = async (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      try {

        const isSuccess = await logic.showDialog(
          'Удалённый аккаунт невозможно восстановить!', {title: 'Удалить аккаунт?', acceptButton: 'Удалить'})

        if (!isSuccess) {
          return
        }

        await logic.deleteAccount(account.id)
        setHomeNav(homeNavRequired.integrationType, undefined)
        const integrationTypeKey = getIntegrationTypeKey(homeNavRequired.integrationType)
        history.push(appRoutes.CommonStore.settings.api.route({accountId: 0, integration: integrationTypeKey}))
        showSuccess('Аккаунт удален!')
      } catch (e) {
        showError('Произошла ошибка во время удаления аккаунта...')
      }
    }

    return (
      <Box className={clsx('nav_account', isSelected && 'nav_account_selected')}>
        <Box
          className={classes.accountInfo}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => onAccountChange(accountIndex)}
          aria-disabled={isSelected}
        >
          <Tooltip title={title} disableHoverListener={title.length < 10} arrow>
            <Typography className={classes.accountName}>
              {title}
            </Typography>
          </Tooltip>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img className={classes.deleteIcon} src={removeAccountIcon} alt="Удаление аккаунта"
                 onClick={onDeleteAccount}/>
            <AccountSyncSwitch account={account} setSync={logic.getSetAccountSyncFunc(account.integrationType)}/>
          </Box>
        </Box>

        {isSelected && (
          <Box className="nav_account_actions">
            <List>
              <ListItem
                component="a"
                href="https://help.mpsklad.ru/"
                target="_blank"
                rel="noopener noreferrer"
                className={clsx('nav_account_link', classes.listItem)}
                button
                disableRipple
              >
                <ListItemIcon className={clsx(classes.listItemIcon, 'nav_account_link')}>
                  <img src={instructions} alt="Инструкция"/>
                </ListItemIcon>
                <ListItemText className={clsx(classes.listItemText, 'nav_account_link')} primary="Инструкция"/>
              </ListItem>

              {currentLinks.map(link => (
                <AccountNavigationLink
                  key={link.route}
                  route={link.route}
                  template={link.template}
                  imgSource={tabData[link.template].imgSource}
                  selectedImgSource={tabData[link.template].selectedImgSource}
                  text={tabData[link.template].text}
                />
              ))}
            </List>
          </Box>
        )}
      </Box>
    )
  }
)

const useStyles =
  makeStyles(() => ({
    accountName: {
      fontSize: 14,
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    accountInfo: {
      padding: '10px 10px 10px 22px',
      cursor: 'pointer'
    },
    listItemIcon: {
      minWidth: 30
    },
    listItem: {
      padding: '5px 0 0 10px',
      '&:hover': {
        backgroundColor: '#FFF'
      }
    },
    listItemText: {
      '& span': {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '14.4px'
      }
    },
    deleteIcon: {
      width: '12px'
    }
  }))