import { composeRoute, createRoute, RouteWithParams } from './routeUtils'
import { StoreRouteParams } from './appRoutes'

export type MoySkladSettingsRoutes<TParams extends Record<string, unknown>> = RouteWithParams<TParams> & {
  template: string
  route: (params: TParams & Record<string, unknown>) => string;
  status: RouteWithParams<TParams>;
  products: RouteWithParams<TParams>;
  orders: RouteWithParams<TParams>;
  configuration: RouteWithParams<TParams>;
  additional: RouteWithParams<TParams>;
  initial: string;
}

export type MoySkladRoutes<TParams extends Record<string, unknown>> = {
  base: RouteWithParams<TParams>;
  settings: MoySkladSettingsRoutes<TParams>;
  orders: RouteWithParams<TParams>;
}

export const createMoySkladRoutes = () => {
  const baseRoute = createRoute<StoreRouteParams>()`/${'integration'}/${'accountId'}`
  const settingsRoute = composeRoute(baseRoute, '/settings')

  return {
    base: baseRoute,
    settings: createMoySkladSettingsRoutes(settingsRoute),
    orders: composeRoute<StoreRouteParams, {}>(baseRoute, '/orders')
  }
}

const createMoySkladSettingsRoutes = <TParams extends StoreRouteParams>(baseSettingsRoute: RouteWithParams<TParams>) => ({
  ...baseSettingsRoute,
  status: composeRoute<TParams, {}>(baseSettingsRoute, '/status'),
  products: composeRoute<TParams, {}>(baseSettingsRoute, '/products'),
  orders: composeRoute<TParams, {}>(baseSettingsRoute, '/orders'),
  configuration: composeRoute<TParams, {}>(baseSettingsRoute, '/configuration'),
  additional: composeRoute<TParams, {}>(baseSettingsRoute, '/additional'),
  initial: baseSettingsRoute.route({accountId: 0, integration: 'MoySklad'} as TParams)
})

export const moySkladRoutes: {
  MoySklad: MoySkladRoutes<StoreRouteParams>;
} = {
  MoySklad: createMoySkladRoutes()
}