import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { LoginScreen } from './LoginScreen'

import { useLogic, useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'

export const AccountScreen = observer(
  () => {
    const logic = useLogic()
    const history = useHistory()
    const classes = useStyles()
    const {user} = useStore()

    if (!user) {
      return <LoginScreen/>
    }

    return (
      <div className="body_wrapper_zero">
        <Box padding={5}>
          <Box marginBottom={3}>
            Вы авторизованы: {[user.name, user.email].filter(_ => _).join(', ')}.
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" width="300px">
            {
              user.isSuperAdmin &&
              <button
                className={clsx('default-button', classes.accountButton)}
                onClick={() => history.push(appRoutes.AdminUsers)}
              >
                <span>Супер-админка</span>
              </button>
            }

            <Box>
              <button
                className={clsx('default-button', classes.accountButton, classes.logoutButton)}
                onClick={logic.logout}
              >
                Выйти
              </button>
            </Box>
          </Box>
        </Box>
      </div>
    )
  })

const useStyles =
  makeStyles(
    ({spacing, palette: {mpSkladSecondary}}) => ({
      accountButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 36.5,
        margin: 0
      },
      logoutButton: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        backgroundColor: mpSkladSecondary.darkBlue1
      }
    }))