import React from 'react'
import { observer } from 'mobx-react-lite'

import { OzonProductsTable } from './OzonProductsTable'
import { WbProductsTable } from './WbProductsTable'
import { YandexMarketProductsTable } from './YandexMarketProductsTable'

import { useStore } from '../hooks/storeHook'

import { wbProductColumns } from '../types/wbColumns'
import { ozonProductColumns } from '../types/ozonColumns'
import { yandexMarketProductColumns } from '../types/yandexMarketColumns'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const StoreProductsTable =
  observer(
    () => {
      const {productStore, getHomeAccount} = useStore()

      const account = getHomeAccount()

      switch (account.integrationType) {
        case IntegrationType.Ozon:
          return <OzonProductsTable
            products={productStore.createOzonProductsTable(account.id)}
            columns={ozonProductColumns}
          />

        case IntegrationType.Wildberries:
          return <WbProductsTable
            products={productStore.createWbProductsTable(account.id)}
            columns={wbProductColumns}
          />

        case IntegrationType.YandexMarket:
          return <YandexMarketProductsTable
            products={productStore.createYandexMarketProductsTable(account.id)}
            columns={yandexMarketProductColumns}
          />

        default:
          throw new Error(`Unsupported integration type: ${(account.integrationType)}`)
      }
    })