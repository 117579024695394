import { add, isAfter, isBefore, isSameDay, set, startOfDay } from 'date-fns'

import { dateUtils } from './dateTimeUtility'
import { OrdersAtDateCount } from '../types/ordersAtDateCount'
import { PrintStoreOrderWithShipmentModel } from '../server/mpsklad_core/Models/PrintStoreOrderWithShipmentModel'
import { maxShipmentDate, minShipmentDate } from '../components/LabelsPostDateFilters'

export const filterPreviewByShipmentDate =
  <TOrder extends PrintStoreOrderWithShipmentModel>(
    newDate: Date | null,
    preview: TOrder[] | null,
    dateCounts: OrdersAtDateCount[]
  ): {
    previewWithShipmentFilters: TOrder[] | null
    shipmentDateMin: Date
    shipmentDateMax: Date
  } => {
    if (preview == null || newDate == null) {
      return {
        previewWithShipmentFilters: preview,
        shipmentDateMin: minShipmentDate,
        shipmentDateMax: maxShipmentDate
      }
    }

    const maxLocalDate = set(dateCounts[dateCounts.length - 1]?.date ?? minShipmentDate, {hours: 24})
    const minLocalDate = set(dateCounts[0]?.date ?? maxShipmentDate, {hours: 0})

    if (isBefore(maxLocalDate, newDate)) {
      return {
        shipmentDateMin: maxLocalDate,
        shipmentDateMax: maxShipmentDate,
        previewWithShipmentFilters: preview.filter(order => isBeforeByDate(maxLocalDate, order.shipmentDate))
      }
    }

    if (isAfter(minLocalDate, newDate)) {
      return {
        shipmentDateMin: maxLocalDate,
        shipmentDateMax: maxShipmentDate,
        previewWithShipmentFilters: preview.filter(order => isBeforeByDate(order.shipmentDate, minLocalDate))
      }
    }

    const previewFiltered = preview.filter(
      order => order.shipmentDate && isSameDay(new Date(order.shipmentDate), newDate))

    // This relies on preview being ordered by shipmentDate by the backend
    const filteredShipmentDateMin = previewFiltered[0]?.shipmentDate
    const filteredShipmentDateMax = previewFiltered[previewFiltered.length - 1]?.shipmentDate

    return {
      previewWithShipmentFilters: previewFiltered,
      shipmentDateMin: filteredShipmentDateMin ? new Date(filteredShipmentDateMin) : minShipmentDate,
      shipmentDateMax: filteredShipmentDateMax ? new Date(filteredShipmentDateMax) : maxShipmentDate
    }
  }

/**
 * Computes amounts of orders that have shipment date that equals today, tomorrow and the day after tomorrow.
 */

export type OrderShipmentInfo = {
  daysCounts: OrdersAtDateCount[];
  laterCount: number;
  beforeCount: number;
}

export const getOrderShipmentDateCounts =
  (orders: PrintStoreOrderWithShipmentModel[] | null): OrderShipmentInfo => {
    if (orders == null) {
      return {
        daysCounts: [],
        laterCount: 0,
        beforeCount: 0
      }
    }

    const now = dateUtils.now

    const dateCounts = [
      {date: now, count: 0},
      {date: add(now, {days: 1}), count: 0},
      {date: add(now, {days: 2}), count: 0}
    ]

    for (const order of orders) {
      if (!order.shipmentDate) {
        continue
      }

      const orderShipmentDate = new Date(order.shipmentDate)

      const orderDateCount = dateCounts.find(_ => isSameDay(_.date, orderShipmentDate))

      if (orderDateCount) {
        orderDateCount.count++
      }
    }

    const daysCounts = dateCounts.filter(_ => _.count > 0)

    const maxLocalDate = set(daysCounts[daysCounts.length - 1]?.date ?? minShipmentDate, {hours: 24})

    const laterCount = orders.filter(order => isBeforeByDate(maxLocalDate, order.shipmentDate)).length

    const beforeCount = orders.length - daysCounts.reduce(
      (day1, day2) => (
        {date: day1.date, count: day1.count + day2.count}
      ), ({date: maxShipmentDate, count: 0})
    ).count - laterCount

    return {
      daysCounts: daysCounts,
      laterCount: laterCount,
      beforeCount: beforeCount
    }
  }

/**
 * Determines whether leftDate is less than rightDate with date precision.
 */
const isBeforeByDate = (
  leftDate: Date | string | null | undefined,
  rightDate: Date | string | null | undefined
): boolean => {
  const left = leftDate ? startOfDay(new Date(leftDate)) : null
  const right = rightDate ? startOfDay(new Date(rightDate)) : null

  if (!left || !right) {
    return false
  }

  return isBefore(left, right)
}