import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { IServerTableData } from '../types/tableTypes'
import { OrderModelBase } from '../server/mpsklad_core/Models/Orders/OrderModelBase'
import clsx from 'clsx'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export type PullOrdersWithErrorsButtonProps<TOrder extends OrderModelBase, TColumnId> = {
  ordersTable: IServerTableData<TOrder, TColumnId>

  pullOrders: () => Promise<void>
}

export const PullOrdersWithErrorsButton =
  observer(
    <TOrder extends OrderModelBase, TColumnId>(
      {ordersTable, pullOrders}: PullOrdersWithErrorsButtonProps<TOrder, TColumnId>) => {
      const {showSuccess} = useMessages()
      const classes = useSettingsPageStyles()

      const [isLoading, setLoading, setFinished] = useBoolState()

      const onClick =
        async () => {
          setLoading()

          try {
            await pullOrders()
            ordersTable.load()
            showSuccess('Обновление заказов в процессе...')
          } finally {
            setFinished()
          }
        }

      if (ordersTable.filteredRowCount <= 0) {
        return null
      }

      return (
        <Box>

          <button
            type="button"
            className={clsx(classes.saveButton, 'default-button')}
            title="Обновить все заказы с ошибками"
            disabled={isLoading}
            onClick={onClick}
          >
            <p>{isLoading ? 'Обновляем' : 'Обновить'}</p>
          </button>

        </Box>
      )
    })