import React, { ChangeEvent, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box, FormHelperText, FormLabel, Grid } from '@material-ui/core'

import { IOSSwitch } from './IOSSwitch'
import { BootstrapInput } from './BootstrapInput'

import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export interface MaximumProductStocksFormProps {
  account: StoreAccountModelBase

  onChange: (maximumProductsStocks: number | undefined) => void
}

export const MaximumProductStocksForm =
  observer(
    ({account, onChange}: MaximumProductStocksFormProps) => {
      const classes = useSettingsPageStyles()

      const [maximumProductStocks, setMaximumProductStocks] = useState(account.maximumProductStocks)

      const isMaximumEnabled = maximumProductStocks != null

      useEffect(
        () => setMaximumProductStocks(account.maximumProductStocks),
        [account.maximumProductStocks])

      useEffect(() => {
        onChange(maximumProductStocks)
      }, [maximumProductStocks, onChange])

      const onChangeIsMaximumEnabled =
        () => setMaximumProductStocks(isMaximumEnabled ? undefined : (account.maximumProductStocks ?? 0))

      const onInternalChange =
        (e: ChangeEvent<HTMLTextAreaElement>) =>
          setMaximumProductStocks(parseInt(e.target.value))

      return (
        <Grid item xs={6}>
          <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
            <Grid item xs={12} className={classes.root}>

              <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Антипарсинг
                  </FormLabel>

                  <FormHelperText className={classes.helperText}>
                    Ограничение на передачу остатка
                  </FormHelperText>
                </Box>
                <IOSSwitch checked={isMaximumEnabled} onChange={onChangeIsMaximumEnabled}/>
              </Box>

              {
                isMaximumEnabled &&
                <Box display="flex">
                  <BootstrapInput
                    fullWidth
                    placeholder="0"
                    value={maximumProductStocks}
                    onChange={onInternalChange}/>
                </Box>
              }
            </Grid>
          </Grid>
        </Grid>
      )
    })