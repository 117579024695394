import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { MyServerTable } from './MyServerTable'
import { YandexMarketScreenTabs } from './YandexMarketScreenTabs'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { yandexMarketOrderColumns } from '../types/yandexMarketOrderColumns'
import { YandexMarketAccountRequiredProps } from '../types/accountProps'
import { OrdersTopPagination } from './OrdersTopPagination'
import { useOrderSearchStyles } from '../hooks/ordersSearchStylesHook'

import search from '../assets/search.svg'
import { BootstrapInput } from './BootstrapInput'

export const YandexMarketOrdersTabsScreen = () =>
  <YandexMarketScreenTabs ScreenComponent={YandexMarketOrdersScreen}/>

enum YandexMarketOrdersTableTab {
  Active = 0,
  WithErrors = 1
}

const tabItems = [{
  name: 'Активные',
  value: YandexMarketOrdersTableTab.Active
}, {
  name: 'С ошибками',
  value: YandexMarketOrdersTableTab.WithErrors
}]

export const YandexMarketOrdersScreen =
  observer(
    ({account}: YandexMarketAccountRequiredProps) => {
      const {api, yandexMarketOrderLoaders} = useLogic()
      const {orderStore: {createYandexMarketOrdersTable}} = useStore()
      const classes = useOrderSearchStyles()

      const yandexMarketOrders = useMemo(
        () => createYandexMarketOrdersTable(account.id),
        [createYandexMarketOrdersTable, account])

      useEffect(() => yandexMarketOrderLoaders.addHandler(yandexMarketOrders.load),
        [yandexMarketOrderLoaders, yandexMarketOrders])

      const isErrorsTab = yandexMarketOrders.findPropFilterValue('hasError') === JSON.stringify(true)

      const onTabChange =
        (newTabIndex: number) =>
          yandexMarketOrders.reload(() =>
            yandexMarketOrders.setPropFilter('hasError', JSON.stringify(newTabIndex === 1)))

      const tabTitles = tabItems.map(_ => <span>{_.name}</span>)

      return (
        <ReactiveTabs
          titles={tabTitles}
          tab={isErrorsTab ? 1 : 0}
          onTabChange={onTabChange}>
          <Box marginY={2} display="flex" justifyContent="space-between" className={classes.container}>
            <Box style={{position: 'relative'}}>
              <Box style={{minWidth: 350, maxWidth: 350}}>
                <FormLabel className={classes.root}>Поиск</FormLabel>
                <BootstrapInput
                  id="search-yandexMarket-orders"
                  onChange={e => yandexMarketOrders.setGlobalFilter(e.target.value)}
                  inputProps={{'aria-label': 'search yandexMarket orders'}}
                  fullWidth
                />
                <FormHelperText className={classes.root}>
                  Id / Id в Yandex.Market / Ваш SKU товара
                </FormHelperText>
              </Box>
              <img className="search-icon" src={search} alt="Поиск" style={{top: 38, right: 20}}/>
            </Box>

            {
              isErrorsTab &&
              <Box marginRight={30}>
                <PullOrdersWithErrorsButton
                  ordersTable={yandexMarketOrders}
                  pullOrders={() => api.order.pullYmOrdersWithErrors(account.id)}
                />
              </Box>
            }

            <OrdersTopPagination table={yandexMarketOrders}/>
          </Box>

          <MyServerTable
            table={yandexMarketOrders}
            hideTopPagination
            columns={yandexMarketOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />

        </ReactiveTabs>
      )
    })