import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box, Grid, Typography } from '@material-ui/core'

import { useBoolState } from '../../hooks/commonHooks'
import { useApi } from '../../hooks/storeHook'

import { useSettingsPageStyles } from '../../hooks/settingsPageStylesHook'
import {
  MoySkladSearchableEntityInput,
  MsEntityDataOptional
} from '../MoySkladSearchableEntityInput'
import { EditStoreAccountMsDataModel } from '../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'
import { MoySkladDataLoadingComponent } from '../MoySkladDataLoading'
import { useMessages } from '../../hooks/snackbarHooks'

export type MsFormData = EditStoreAccountMsDataModel

export type StoreAccountMsData = {
  organization: MsEntityDataOptional
  counterparty: MsEntityDataOptional

  contract: MsEntityDataOptional
  salesChannel: MsEntityDataOptional
  project: MsEntityDataOptional

  readonly formData: MsFormData
}

export type MoySkladDataFormProps = {
  msData: StoreAccountMsData | undefined
  onChange: (msData: MsFormData) => Promise<void>
  loadMsData: () => Promise<void>
}

export const CompanySettings = observer(
  ({msData, onChange, loadMsData}: MoySkladDataFormProps) => {
    const [isLoading, setLoading, setNotLoading] = useBoolState()
    const [isMsDataLoading, setMsDataLoading, setMsStoresNotLoading] = useBoolState()

    const api = useApi()
    const classes = useSettingsPageStyles()
    const {showError} = useMessages()

    useEffect(() => {
      if (!msData) {
        setMsDataLoading()
        loadMsData().finally(setMsStoresNotLoading)
      }
    }, [msData, loadMsData, setMsDataLoading, setMsStoresNotLoading])

    if (isMsDataLoading || !msData) {
      return <MoySkladDataLoadingComponent/>
    }

    const onInternalChange = async () => {
      if (msData.formData.msOrganization === '' || msData.formData.msCounterparty === '') {
        showError('Необходимо выбрать организацию продавца и контрагент покупателя')
        return
      }

      setLoading()
      await onChange(msData.formData)
      setNotLoading()
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ ОРГАНИЗАЦИИ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <MoySkladSearchableEntityInput
                    isRequired
                    id="msOrganization"
                    data={msData.organization}
                    searchEntities={api.userSync.getMoySkladOrganizations}
                    label="Организация продавца"
                    hint="Введите наименование или код для поиска."
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <MoySkladSearchableEntityInput
                    id="msSalesChannel"
                    data={msData.salesChannel}
                    searchEntities={api.userSync.getMoySkladSalesChannels}
                    label="Канал продаж"
                    hint="Введите наименование или описание для поиска."
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <MoySkladSearchableEntityInput
                    isRequired
                    id="msCounterparty"
                    data={msData.counterparty}
                    searchEntities={api.userSync.getMoySkladCounterparties}
                    label="Контрагент покупателя"
                    hint={
                      <>
                        Введите наименование или код для поиска.
                        <br/>
                        Возможен выбор контрагента только с типом "Юридическое лицо".
                      </>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <MoySkladSearchableEntityInput
                    id="msProject"
                    data={msData.project}
                    searchEntities={api.userSync.getMoySkladProjects}
                    label="Проект"
                    hint="Введите название проекта."
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <MoySkladSearchableEntityInput
                    id="msContract"
                    data={msData.contract}
                    searchEntities={api.userSync.getMoySkladContracts}
                    label="Договор"
                    hint={
                      <>
                        Введите номер договора или комментарий для поиска.
                        <br/>
                        Возможен выбор только договора комиссии.
                      </>
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex">
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onInternalChange}
          >
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>
        </Box>
      </Box>
    )
  }
)