import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, CircularProgress, FormHelperText } from '@material-ui/core'

import { OzonPrintOrderModel } from '../server/mpsklad_core/Models/OzonPrintOrderModel'
import { MyTable } from './MyTable'
import { OzonPrintOrderColumnId, ozonPrintOrderColumns } from '../types/ozonColumns'
import { ITableData } from '../types/tableTypes'

export type OzonPreviewPrintTableProps = {
  orders: ITableData<OzonPrintOrderModel, OzonPrintOrderColumnId>
}

export const OzonPreviewPrintTable =
  observer(
    ({orders}: OzonPreviewPrintTableProps) => {
      if (orders === null) {
        return <Box padding={2}>
          <CircularProgress size={20}/>
        </Box>
      }

      return (
        <>
          <FormHelperText>
            Выбрано заказов: {orders.selectedRowCount}
          </FormHelperText>

          <MyTable
            table={orders}
            columns={ozonPrintOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </>)
    })