import React from 'react'
import { TablePagination2 } from './TablePagination'

import { ITableData } from '../types/tableTypes'

type OrdersTableProps<TRow extends object, TColumnId> = {
  table: ITableData<TRow, TColumnId>
}
export const OrdersTopPagination =
  <TRow extends object, TColumnId>
  ({table}: OrdersTableProps<TRow, TColumnId>) => {

    return (
      <div
        style={{
          position: 'absolute',
          right: '0',
          bottom: 10
        }}
      >
        <TablePagination2
          table={table}
          labelRowsPerPage="Заказов на странице:"
          style={{
            position: 'absolute',
            right: 90,
            color: '#888888',
            fontSize: 10
          }}
        />
      </div>
    )
  }