import React, { ReactNode, ReactText, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'

import debounce from 'lodash/debounce'

import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete'
import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { DefaultSelect } from './DefaultSelect'

import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

import { emDash } from '../common/stringUtility'

import { MoySkladApiEntityOption } from '../server/mpsklad_core/Models/MoySkladApiEntityOption'

export type MsEntityDataBase = {
  options: MoySkladApiEntityOption[]
  searchTerm: string
  isSearchLoading: boolean
}

export type MsEntityDataOptional =
  MsEntityDataBase & {
  value: MoySkladApiEntityOption | null
}

export type MoySkladSearchableEntityInputProps =
  Pick<AutocompleteProps<MoySkladApiEntityOption, undefined, undefined, undefined>, 'id' | 'loading' | 'loadingText'>
  & {
  isRequired?: boolean
  data: MsEntityDataOptional
} & {
  searchEntities: (searchTerm: string) => Promise<MoySkladApiEntityOption[]>
  label: ReactText
  hint?: ReactNode
}

export const MoySkladSearchableEntityInput = observer(
  ({isRequired = false, data, searchEntities, label, hint}: MoySkladSearchableEntityInputProps) => {
    const innerOptions = useMemo(() => {
      return isRequired
             ? data.options
             : [{id: '', name: emDash} as MoySkladApiEntityOption, ...data.options]
    }, [isRequired, data.options])

    const classes = useSettingsPageStyles()
    const searchEntitiesDebounced = useSearchEntitiesDebounced(data, searchEntities)

    useEffect(() =>
        reaction(
          () => data.searchTerm,
          () => {
            data.isSearchLoading = true
            searchEntitiesDebounced()
          }),
      [data, searchEntitiesDebounced])

    return (
      <>
        <Box marginBottom={1}>
          <FormLabel component="legend" className={classes.label}>
            <span className={classes.dash}>—</span>{label}{isRequired && <span className={classes.star}>*</span>}
          </FormLabel>
        </Box>

        <DefaultSelect
          value={data.value?.id}
          options={innerOptions.map(option => ({value: option.id, label: option?.name ?? emDash}))}
          onChange={(selectedValue) => {
            const selectedOption = innerOptions.find(option => option.id === selectedValue)
            if (isRequired && selectedOption?.id === '') {
              return
            }
            data.value = selectedOption || null
          }}
          fullWidth
          placeholder="Введите для поиска"
          onInputChange={(newSearchTerm) => { data.searchTerm = newSearchTerm }}
          searchable
          isLoading={data.isSearchLoading}
        />

        {hint && (
          <FormHelperText className={classes.helperText}>
            {hint}
          </FormHelperText>
        )}
      </>
    )
  }
)

const useSearchEntitiesDebounced =
  (data: MsEntityDataBase, searchEntities: (searchTerm: string) => Promise<MoySkladApiEntityOption[]>) =>
    useMemo(() =>
        debounce(async () => {
            data.isSearchLoading = true

            try {
              data.options = await searchEntities(data.searchTerm)
            } finally {
              data.isSearchLoading = false
            }
          },
          800),
      [data, searchEntities])