import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { ReactiveTabs } from '../../ReactiveTabs'
import { MyServerTable } from '../../MyServerTable'
import { PullOrdersWithErrorsButton } from '../../PullOrdersWithErrorsButton'

import { useLazyEffect } from '../../../hooks/commonHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { useMoySkladOrderColumns } from '../../../types/moySkladOrderColumns'
import { BootstrapInput } from '../../BootstrapInput'
import { useOrderSearchStyles } from '../../../hooks/ordersSearchStylesHook'
import search from '../../../assets/search.svg'

const tabItems = [{
  name: 'Активные'
}, {
  name: 'С ошибками'
}]

export const MoySkladOrdersScreen =
  observer(() => {
    const {orderStore: {moySkladOrders}, syncStore: {moySkladAccount}} = useStore()
    const moySkladOrderColumns = useMoySkladOrderColumns()
    const classes = useOrderSearchStyles()

    const {api, msOrderLoaders} = useLogic()

    useLazyEffect(() => moySkladOrders.setPropFilter('hasError', JSON.stringify(false)))

    useEffect(() => msOrderLoaders.addHandler(moySkladOrders.load), [msOrderLoaders, moySkladOrders])

    const isErrorsTab = moySkladOrders.findPropFilterValue('hasError') === JSON.stringify(true)

    const onTabChange =
      (newTabIndex: number) =>
        moySkladOrders.reload(() => moySkladOrders.setPropFilter('hasError', JSON.stringify(newTabIndex === 1)))

    if (moySkladAccount == null) {
      return <Box padding={3}>Добавьте аккаунт МоегоСклада для работы с заказами.</Box>
    }

    const tabTitles = tabItems.map(_ => <span>{_.name}</span>)

    return (
      <Box style={{marginTop: -40}}>
        <ReactiveTabs titles={tabTitles} tab={isErrorsTab ? 1 : 0} onTabChange={onTabChange}>
          <Box marginY={2} display="flex" justifyContent="space-between" className={classes.container}>
            <Box style={{position: 'relative'}}>
              <Box style={{minWidth: 350, maxWidth: 350}}>
                <FormLabel className={classes.root}>Поиск</FormLabel>
                <BootstrapInput
                  onChange={e => moySkladOrders.setGlobalFilter(e.target.value)}
                  inputProps={{'aria-label': 'search ms orders'}}
                  fullWidth
                />
                <FormHelperText className={classes.root}>
                  Id / GUID в МоёмСкладе / Id в Ozon / Id в Wildberries / Id в YandexMarket
                </FormHelperText>
              </Box>
              <img className="search-icon" src={search} alt="Поиск" style={{top: 38, right: 20}}/>
            </Box>

            {
              isErrorsTab &&
              <PullOrdersWithErrorsButton
                ordersTable={moySkladOrders}
                pullOrders={api.order.pullMoySkladOrdersWithErrors}
              />
            }
          </Box>

          <MyServerTable
            table={moySkladOrders}
            columns={moySkladOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </ReactiveTabs>
      </Box>
    )
  })