import React from 'react'

import { Box, CircularProgress, FormHelperText } from '@material-ui/core'

export const MoySkladDataLoadingComponent = () => (
  <Box
    width="calc(100vw - 240px)"
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <CircularProgress/>
      <Box marginTop={1}>
        <FormHelperText>Загружаем данные из МоегоСклада</FormHelperText>
      </Box>
    </Box>
  </Box>
)