import { useMemo } from 'react'

import { TableColumn } from './tableTypes'
import { createColumns, MyColumnParam } from './columnGroupCreator'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { MoySkladWbIdCell } from '../components/tableCells/MoySkladWbIdCell'
import { MoySkladOzonIdCell } from '../components/tableCells/MoySkladOzonIdCell'
import { MoySkladOrderStatusCell } from '../components/tableCells/MoySkladOrderStatusCell'
import { MoySkladOrderSyncLogsCell } from '../components/tableCells/MoySkladOrderSyncLogsCell'
import { MoySkladOrderSyncErrorCell } from '../components/tableCells/MoySkladOrderSyncErrorCell'
import { MoySkladYandexMarketIdCell } from '../components/tableCells/MoySkladYandexMarketIdCell'

import { useStore } from '../hooks/storeHook'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { MoySkladOrderModel } from '../server/mpsklad_core/Models/Orders/MoySkladOrderModel'

export type MoySkladOrderColumnId =
  keyof MoySkladOrderModel
  | 'ozonId'
  | 'wbId'
  | 'yandexMarketId'
  | 'logs'

export type MoySkladOrderColumn =
  TableColumn<MoySkladOrderColumnId, MoySkladOrderModel>

export const useMoySkladOrderColumns =
  (): MoySkladOrderColumn[] => {
    const {allowedIntegrations} = useStore()

    return useMemo(
      (): MoySkladOrderColumn[] => {
        const columnParams: MyColumnParam<MoySkladOrderColumnId, MoySkladOrderModel>[] = [{
          id: 'id',
          title: 'Id'
        }, {
          id: 'createdAt',
          title: 'Дата создания',
          CellComponent: TableDateCell
        }, {
          id: 'moySkladId',
          title: 'GUID в МоёмСкладе'
        }, {
          id: 'status',
          title: 'Статус',
          CellComponent: MoySkladOrderStatusCell
        }]

        if (allowedIntegrations[IntegrationType.Ozon]) {
          columnParams.push({
            id: 'ozonId',
            title: 'Id в Ozon',
            CellComponent: MoySkladOzonIdCell
          })
        }

        if (allowedIntegrations[IntegrationType.Wildberries]) {
          columnParams.push({
            id: 'wbId',
            title: 'Id в Wildberries',
            CellComponent: MoySkladWbIdCell
          })
        }

        if (allowedIntegrations[IntegrationType.YandexMarket]) {
          columnParams.push({
            id: 'yandexMarketId',
            title: 'Id в YandexMarket',
            CellComponent: MoySkladYandexMarketIdCell
          })
        }

        columnParams.push({
          id: 'syncErrorId',
          title: 'Ошибки',
          CellComponent: MoySkladOrderSyncErrorCell
        }, {
          id: 'logs',
          title: 'Логи',
          CellComponent: MoySkladOrderSyncLogsCell
        })

        return createColumns(...columnParams)
      },
      [allowedIntegrations])
  }